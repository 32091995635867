'use client'

const { useRef, forwardRef } = require('react')
import CalculatorsJson from './calculators.json'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'

import ArrowRight from '@/images/icons/arrow-right.svg'

import styles from './CalculatorSelect.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const CalculatorSelect = ({}) => {
    const sectionRef = useRef(null)
    const boxes = useRef([])

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                boxes.current.forEach((box, i) => {
                    setTimeout(() => {
                        box.classList.add(classes('enter'))
                    }, i * 100)
                })
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.3],
        },
    })

    return (
        <SectionWrap ref={sectionRef} className={classes('wrapper')}>
            <div className={classes('grid')}>
                {CalculatorsJson.map((item, index) => (
                    <CalculatorItem {...item} id={item.key} key={item.key} ref={(el) => (boxes.current[index] = el)} />
                ))}
            </div>
        </SectionWrap>
    )
}

const CalculatorItem = forwardRef(({ id, title, description, type, url }, ref) => {
    return (
        <a className={classes('option', `option_${type}`)} id={classes(id)} href={url} ref={ref}>
            {type == 4 && <img src={`/images/money.png`} className={classes('image')} />}
            <div className={classes('title')}>{title}</div>
            <div className={classes('description')}>{description}</div>
            <div className={classes('button')}>
                <span>Try it out</span>
                <ArrowRight />
            </div>
        </a>
    )
})

export default CalculatorSelect

'use client'

import styles from './FaceCardSection.module.scss'
import classNames from 'classnames/bind'
import FaceCard from '@/components/Elements/FaceCard/FaceCard'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef, useState } from 'react'
import Title from '@/components/Elements/Title/Title'

const classes = classNames.bind(styles)

const FaceCardSection = ({ attributes }) => {
    const { title, subText, cards, styling } = attributes
    const [showMembers, setShowMembers] = useState(false)

    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setShowMembers(true)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0],
        },
    })

    return (
        <SectionWrap styling={styling} ref={sectionRef}>
            <div className={classes('wrapper')}>
                <Title title={title} description={subText} />
                <div className={classes('cards')}>
                    {cards &&
                        cards.map((card, i) => {
                            return <FaceCard key={i} visible={showMembers} timing={1 + (i % 3)} {...card} />
                        })}
                </div>
            </div>
        </SectionWrap>
    )
}

export default FaceCardSection
